// felix@TODO: for assembl: style-1 is style-3 with some rectangle, what is done down there is dirty,
// style-1 and style-3 are mixed. Try to add variation classes to keep only one style-* class
// I tried to keep all legacy code in comment to work on it again

@media screen and (min-width: 768px) {
  .projects {
    .row {
      display: flex;
    }

    .logo-img {
      flex-basis: 0;
      flex-grow: 1;
      flex-wrap: wrap;
    }
  }
}

.projects .projects-body .row {
  margin: 0;
}

.projects .projects-body .image {
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  height: 50rem;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 768px) {
  .projects .projects-body .image:hover .picture {
    transform: translateY(-5rem);
  }
  .projects .projects-body .image:hover.style-3 .picture {
    transform: translateX(5rem);
  }
  .projects .projects-body .image .picture {
    position: relative;
    transition: transform 2s;
  }
}
@media screen and (min-width: 992px) {
  .projects .projects-body .image {
    height: 71.5rem;
  }
}

.projects .projects-body .picture {
  bottom: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  right: 0;
}

//STYLE 1 : No parallax ( assembl )
// .projects .projects-body .style-1 .logo {
//   margin-top: 8.2rem;
// }
.projects .projects-body .style-1 .picture {
  position: absolute;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .projects .projects-body .style-1::before {
    border: 0.12rem solid #fff;
    bottom: 5rem;
    content: '';
    left: 6rem;
    position: absolute;
    right: 18.79rem;
    top: 13.9rem;
  }
  .projects .projects-body .style-1::after {
    border: 0.12rem solid #fff;
    bottom: 9.72rem;
    content: '';
    left: 12.19rem;
    position: absolute;
    right: 12.6rem;
    top: 9.2rem;
  }
  // .projects .projects-body .style-1 .logo {
  //   margin-top: 17.3rem;
  //   max-height: 13rem;
  //   min-width: 40rem;
  //   z-index: 2;
  // }
  // .projects .projects-body .style-1 .picture {
  //   bottom: -5rem;
  //   left: 8.7rem;
  //   z-index: 2;
  // }
}
@media screen and (min-width: 992px) {
  .projects .projects-body .style-1::before {
    bottom: 8.2rem;
    left: 10.5rem;
    right: 28.5rem;
    top: 15.22rem;
  }
  .projects .projects-body .style-1::after {
    bottom: 15.3rem;
    left: 19.5rem;
    right: 19.5rem;
    top: 8.2rem;
  }
  // .projects .projects-body .style-1 .logo {
  //   margin-left: 10rem;
  //   margin-top: 25rem;
  //   max-height: 15rem;
  //   min-width: 35rem;
  // }
  // .projects .projects-body .style-1 .picture {
  //   left: 15rem;
  // }
}
// @media screen and (min-width: 1200px) {
//   .projects .projects-body .style-1 .logo {
//     left: 2%;
//     position: absolute;
//     max-height: 19rem;
//     min-width: 44rem;
//   }
//   .projects .projects-body .style-1 .picture {
//     left: auto;
//     right: 5rem;
//   }
// }

//STYLE 2 : background and parallax (Medicamentum)
.projects .projects-body .style-2 .parallax-container {
  height: 50rem;
  position: relative;
}

.projects .projects-body .style-2 .parallax-images {
  position: absolute;
  width: 100%;
}

.projects .projects-body .style-2 .parallax-images {
  background-size: 80rem !important;
  height: 1000%;
  z-index: 0;
}

.projects .projects-body .style-2 .logo {
  // margin-top: 7rem;
  position: relative;
  // z-index: 2;
}

.projects .projects-body .style-2 .picture {
  position: absolute;
}

@media screen and (min-width: 768px) {
  .projects .projects-body .style-2 .parallax-images {
    background-size: 100rem !important;
  }
  .projects .projects-body .style-2 .picture {
    bottom: -5rem;
  }
  .projects .projects-body .style-2 .logo {
    margin-left: -12rem;
    margin-top: 20.9rem;
    max-height: 10rem;
    min-width: 40rem;
  }
}

@media screen and (min-width: 992px) {
  .projects .projects-body .style-2 .parallax-images {
    background-size: 200rem !important;
  }
  .projects .projects-body .style-2 .logo {
    margin-top: 31rem;
    max-height: 15rem;
    min-width: 50.5rem;
  }
  .projects .projects-body .style-2 .picture {
    max-height: 61rem;
    max-width: 40rem;
  }
}

@media screen and (min-width: 1200px) {
  .projects .projects-body .style-2 .logo {
    left: 8%;
    margin-top: 31rem;
    position: absolute;
    min-width: 65.5rem;
  }
  .projects .projects-body .style-2 .picture {
    left: auto;
    right: 15rem;
    max-width: 43rem;
    max-height: 67rem;
  }
}

//STYLE 3 : background, linear-gradient and parallax (SNUipp)
.projects .projects-body .style-3 .parallax-container {
  height: 50rem;
  position: relative;
}

.projects .projects-body .style-3 .parallax-images {
  position: absolute;
  width: 100%;
}

.projects .projects-body .style-3 .parallax-images {
  background-size: 100%;
  height: 1000%;
  z-index: 0;
}

.projects .projects-body .style-1 .logo,
.projects .projects-body .style-3 .logo {
  // margin-top: 6rem;
  position: relative;
  // z-index: 10;
}

.projects .projects-body .style-1 .picture,
.projects .projects-body .style-3 .picture {
  bottom: 4.7rem;
  height: 26.9rem;
  position: absolute;
  width: auto;
}

@media screen and (min-width: 768px) {
  .projects .projects-body .style-3 .parallax-images {
    background-size: 100% !important;
  }
  .projects .projects-body .style-1 .logo,
  .projects .projects-body .style-3 .logo {
    // margin-top: 12.4rem;
    width: 15rem;
  }
  .projects .projects-body .style-1 .picture,
  .projects .projects-body .style-3 .picture {
    bottom: 7rem;
    height: 35.9rem;
    left: -15rem;
  }
}

@media screen and (min-width: 992px) {
  .projects .projects-body .style-1 .logo,
  .projects .projects-body .style-3 .logo {
    // margin-left: 20rem;
    // margin-top: 20.7rem;
    width: 20rem;
  }
  .projects .projects-body .style-1 .picture,
  .projects .projects-body .style-3 .picture {
    bottom: 12rem;
    height: 48rem;
    left: -20rem;
  }
}

@media screen and (min-width: 1200px) {
  .projects .projects-body .style-1 .logo,
  .projects .projects-body .style-3 .logo {
    // left: 10%;
    // margin-top: 20.7rem;
    // position: absolute;
    width: 25rem;
  }
  .projects .projects-body .style-1 .picture,
  .projects .projects-body .style-3 .picture {
    bottom: 5.9rem;
    height: 59.6rem;
    left: auto;
    right: -20rem;
  }
}

//STYLE 4 : Logo only (Polisemi)  Not used anymore, keeping just in case
// .projects .projects-body .style-4 {
//   background-image: radial-gradient(circle at 49% 43%, #fff, #f4f4f4);
//   @media screen and (max-width: 767px) {
//     box-shadow: none;
//   }
//   height: auto;
// }
// @media screen and (min-width: 768px) {
//   .projects .projects-body .style-4 {
//     height: 50rem;
//   }
//   .projects .projects-body .style-4 .picture {
//     left: 0;
//     position: absolute;
//     top: 0;
//   }
//   .projects .projects-body .style-4 .logo {
//     margin-left: -35rem;
//     margin-top: 16rem;
//     position: absolute;
//   }
// }
// @media screen and (min-width: 992px) {
//   .projects .projects-body .style-4 {
//     height: 65rem;
//   }
//   .projects .projects-body .style-4 .picture {
//     top: -2rem;
//   }
// }
// @media screen and (min-width: 992px) and (min-width: 1920px) {
//   .projects .projects-body .style-4 .picture {
//     width: 101%;
//   }
// }
// @media screen and (min-width: 992px) {
//   .projects .projects-body .style-4 .logo {
//     margin-left: -49.5rem;
//     margin-top: 21.5rem;
//     width: 99rem;
//   }
// }

.projects .projects-body .desc {
  background-image: radial-gradient(circle at 49% 43%, #fff, #f4f4f4);
  z-index: -4;
}
.projects .projects-body .desc h1 {
  margin-bottom: 1.5rem;
  padding-top: 3.3rem;
  text-transform: none;
}
.projects .projects-body .desc p {
  margin-bottom: 2.6rem;
  margin-left: 1.4rem;
  margin-right: 1.4rem;
}
.projects .projects-body .desc .btn {
  background-color: #c8c8c8;
  border-radius: 2.4px;
  font-family: 'Montserrat-Light', 'Helvetica Neue', 'Arial', sans-serif;
  height: 45px;
  margin-bottom: 4rem;
  width: 217px;
}
.projects .projects-body .desc .btn:hover,
.projects .projects-body .desc .btn:active {
  background-color: #ffc200;
}
@media screen and (min-width: 768px) {
  .projects .projects-body .desc h1 {
    font-size: 3rem;
    margin-bottom: 2.3rem;
    padding-top: 5.1rem;
  }
  .projects .projects-body .desc p {
    margin-bottom: 3.6rem;
    margin-left: 12.7rem;
    margin-right: 12.7rem;
  }
  .projects .projects-body .desc .btn {
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 992px) {
  .projects .projects-body .desc h1 {
    font-size: 5rem;
    padding-top: 6.5rem;
  }
  .projects .projects-body .desc p {
    font-size: 1.6rem;
    margin-left: 28.7rem;
    margin-right: 28.7rem;
    padding-bottom: 0.4rem;
  }
  .projects .projects-body .desc .btn {
    height: 55px;
    margin-bottom: 8rem;
    padding-top: 1.5rem;
    width: 260px;
  }
}

.projects .logo-block {
  background-color: #fff;
  z-index: 20;
}
.projects .logo-block .logo-img {
  align-items: center;
  border: solid 0.1rem #d8d8d8;
  display: flex;
  height: 13.26rem;
  justify-content: center;

  &.active img,
  &:hover img {
    filter: grayscale(0%);
  }

  img {
    transition: all 0.3s ease;
    filter: grayscale(100%);
    max-height: 6.2rem;
    max-width: 15.8rem;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .projects .logo-block .logo-img {
    height: 10.7rem;
  }
  .projects .logo-block .logo-img img {
    max-height: 4.6rem;
    max-width: 11.6rem;
    padding: 0;
  }
}

@media screen and (min-width: 992px) {
  .projects .projects-body .desc h1 {
    font-size: 5rem;
    padding-top: 6.5rem;
  }
  .projects .projects-body .desc p {
    font-size: 1.6rem;
    margin: 2.3rem 29.7rem 3.6rem;
  }
  .projects .projects-body .desc .btn {
    height: 5.5rem;
    margin-bottom: 8rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    width: 26rem;
  }
  .projects .logo-block .logo-img {
    height: 20rem;
  }
  .projects .logo-block .logo-img img {
    max-height: 8.6rem;
    max-width: 18.1rem;
  }
}

.projects .floating-menu {
  position: fixed;
  right: 5rem;
  text-align: right;
  top: 20vh;
  z-index: 0;
}
.projects .floating-menu ul {
  float: right;
  list-style: none;
  text-align: right;
}
.projects .floating-menu ul a {
  text-align: right;
  text-decoration: none;
  width: 0.85rem;
}
.projects .floating-menu ul a.menu-05 img {
  margin-top: 2rem;
}
.projects .floating-menu ul li {
  position: relative;
}
.projects .floating-menu ul li.on {
  margin-top: 0.8em;
}
.projects .floating-menu ul li .line {
  background: #c8c8c8;
  height: 0.3rem;
  position: absolute;
  right: 0;
  width: 1.5rem;
}
.projects .floating-menu ul li.m:hover span {
  color: #4a4a4a;
  font-size: 2.2rem;
  opacity: 1;
  visibility: visible;
}
.projects .floating-menu ul li.m:hover .line {
  background: transparent;
  margin: 0.1rem;
  width: 100%;
}
.projects .floating-menu ul .on span {
  border-bottom: solid 0.2rem #4a4a4a;
  color: #4a4a4a;
  font-size: 2.2rem;
  opacity: 1;
  padding-bottom: 0;
  visibility: visible;
}
.projects .floating-menu ul .on .line {
  background-color: transparent;
  width: 100%;
}
.projects .floating-menu ul .on .sliding-u-r-l:hover::after {
  background: transparent;
}
.projects .floating-menu ul span {
  color: transparent;
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 0;
  opacity: 0;
  text-align: right;
  transition: visibility 2s, opacity 0.5s linear;
  visibility: hidden;
  width: 0.85rem;
}

.sliding-u-r-l {
  padding-bottom: 3px;
  position: relative;
}
.sliding-u-r-l::after {
  background: transparent;
  bottom: 0;
  content: '';
  height: 3px;
  position: absolute;
  right: 0;
  transition: width 0.5s ease, background-color 0.5s ease;
  width: 0;
}
.sliding-u-r-l:hover::after {
  background: #c8c8c8;
  width: 100%;
}

.projects .projects-footer {
  z-index: 20;
  position: relative;
}

//felix@TODO: What is done right after is hell, it is a quick fix until this page style is rework ( Check l.1 comment )
// DO NOT try to improve that, it should NOT be maintain but REMOVED

.projects .projects-body .style-1 .picture,
.projects .projects-body .style-2 .picture,
.projects .projects-body .style-3 .picture {
  max-width: 80vw;
  object-fit: contain;
}
#section-medicamentum {
  .picture {
    margin-bottom: 5%;
  }
}
#section-keyple {
  .picture {
    margin-bottom: 5%;
  }
}
#section-snuipp-fsu {
  .logo {
    width: 10rem;
  }
}

@media screen and (max-width: 767px) {
  // Override bootstrap values just on mobile
  #section-fafiec {
    .logo {
      margin-top: 9rem;
    }
  }
  #section-medicamentum {
    .logo {
      margin-top: 5rem;
    }
    .picture {
      max-height: 65%;
    }
  }
  #section-assembl {
    .logo {
      margin-top: 7rem;
    }
  }
  #section-keyple {
    .logo {
      margin-top: 5rem;
    }
    .picture {
      max-height: 65%;
    }
  }
  #section-snuipp-fsu {
    .logo {
      margin-top: 4rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .projects .projects-body .style-1 .picture,
  .projects .projects-body .style-2 .picture,
  .projects .projects-body .style-3 .picture {
    max-width: unset;
    object-fit: initial;
  }
  #section-medicamentum {
    .picture {
      max-width: 278px;
    }
  }
  #section-keyple {
    .picture {
      max-width: 278px;
    }
  }
}

@media screen and (min-width: 992px) {
  .projects .projects-body .style-1 .picture,
  .projects .projects-body .style-2 .picture,
  .projects .projects-body .style-3 .picture {
    max-width: unset !important;
    object-fit: initial;
  }
  #section-fafiec {
    .logo {
      width: 30rem;
    }
  }
  #section-assembl {
    .logo {
      width: 30rem;
    }
  }
  #section-snuipp-fsu {
    .logo {
      width: 15rem;
      margin-left: 5rem;
    }
  }
}
